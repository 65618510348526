import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Row, Col, Card, Divider, Button, Alert} from "antd";
import gclLogo from "../assets/images/gcl-logo.svg"
import eventopolisLogo from "../assets/images/eventopolis-logo.png"

const ContactPage = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`Contatti | ${intl.formatMessage({ id: "title" })}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-contact">
                <div className="container text-center pt-4">
                    <h1>Contatti</h1>
                </div>
                <section className="pt-4">
                    <div className="container">
                        <Row gutter={24} type="flex">
                            <Col xs={24} lg={12} className="text-center mb-4">
                                <div className="logo-container">
                                <img src={gclLogo} alt="Golf Club Lugano - Logo" className="gcl-logo mb-3"/>
                                </div>
                                <h3>Golf Club Lugano</h3>
                                <p>Via Boett 2 <br/>
                                    6983 Magliaso</p>
                                <p><b>Telefono</b><br/>
                                    +41 91 606 15 57</p>
                                <p><b>Email</b><br/>
                                    <a href="mailto:info@golflugano.ch">info@golflugano.ch</a></p>
                            </Col>
                            <Col xs={24} lg={12} className="text-center mb-4">
                                <div className="logo-container">
                                <img src={eventopolisLogo} alt="Eventopolis - Logo" className="eventopolis-logo mb-3"/>
                                </div>
                                <h3>Eventopolis SA</h3>
                                <p>Via Pietro Peri 17 <br/>
                                    6900 Lugano</p>
                                <p><b>Telefono</b><br/>
                                    +41 91 971 46 57</p>
                                <p><b>Email</b><br/>
                                    <a href="mailto:wfsopen@eventopolis.ch">wfsopen@eventopolis.ch</a></p>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default ContactPage
